

























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Axios from 'axios'
import { mapState } from 'vuex'
import { EventBus } from '@/main'
import { Configuration } from '@/configuration'
import API from '@/api'

@Component({
  computed: {
    ...mapState(['user', 'token', 'onlyActives']),
  },
  data() {
    return {
      availables: null,
      hideOrNot: true,
      id: '',
      properties: {
        factura: true,
        credito: true,
        tasa: 6,
        cuota: 24,
        offset: 0,
        isLoading: false,
      },
    }
  },
  watch: {
    'hideOrNot'(val: any) {
      this.$store.commit('hideOrNot', val)
      // @ts-ignore
      this.searchAvailableProjects()
    },
    properties: {
      handler(properties) {
        let temp = !properties.factura && !properties.credito || properties.isLoading
        EventBus.$emit('cantPressButtons', temp)
      },
      deep: true,
    },
  },
  beforeMount() {
    EventBus.$on('getProjects', () => {
      // @ts-ignore
      this.searchProjects()
    })

    EventBus.$on('viewMoreProjects', () => {
      // @ts-ignore
      this.appendProjects()
    })
  },
  beforeDestroy() {
    EventBus.$off(['getProjects', 'viewMoreProjects'])
  },
  methods: {
    searchProjects() {
      this.$data.properties.isLoading = true
      let data = Object.assign({}, this.$data.properties)
      data.offset = 0
      API.getProjects(data).then( projects => {
        EventBus.$emit('projects', projects)
        // this.$data.availables = projects.map((i: any) => i.id).filter((i: any) => i != null)
        this.$data.properties.offset = projects.length
        this.$data.properties.isLoading = false
      }).catch( error => {
        EventBus.$emit('projects', [])
        this.$data.properties.isLoading = false
      })
    },
    searchAvailableProjects() {
      this.$data.properties.isLoading = true
      let data = Object.assign({}, this.$data.properties)
      data.offset = 0
      API.getProjects(data).then( projects => {
        // .currentAmountTotalPercentage().toFixed(1) < 99
        // @ts-ignore
        let filteredProjects = projects.filter((i: any) => (((parseInt(i.monto - i.monto_disponible)*100) / i.monto)).toFixed(2) <= 99)
        EventBus.$emit('projects', filteredProjects)
        this.$data.properties.offset = filteredProjects.length
        this.$data.properties.isLoading = false
      }).catch( error => {
        EventBus.$emit('projects', [])
        this.$data.properties.isLoading = false
      })
      
    },
    appendProjects() {
     // if (!this.$store.state.onlyActives) {
        this.$data.properties.isLoading = true
        let data = this.$data.properties
        API.getProjects(data).then( projects => {
          EventBus.$emit('appendProjects', projects)
          this.$data.properties.offset += projects.length
          this.$data.properties.isLoading = false
        })
     // }
    },
  },
})
export default class Filters extends Vue {}
