












































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Axios from 'axios'
import { Backend, SocketPort, RedCapital, SelectedCountry, TitleTemplatePublic } from '@/configuration'
import Filters from '@/components/Filters.vue'
import ProjectCard from '@/components/ProjectCard.vue'
import { EventBus } from '@/main'
import API from '@/api'
import { Project } from '@/models/Project'
import Echo from 'laravel-echo'
import { isUserLogin } from '@/utils'
import { mapState } from 'vuex'

/*const EchoInstance = new Echo({
  broadcaster: 'socket.io',
  host: `${Backend}:${SocketPort}`,
})*/

@Component({
  metaInfo: {
    title: 'Invertir',
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    },
  },
  computed: {
    ...mapState(['onlyActives']),
  },
  data() {
    return {
      projects: null,
      secretProjects: [],
      showSecret: false,
      cantPressButtons: false,
      canShow: false,
      canLoad: false,
      coords: {
        max: 0,
        y: 0,
      },
      temporals: null,
    }
  },
  async beforeMount() {
     this.$store.commit('hideOrNot', true)
    EventBus.$on('projects', (projects : Array<Project>) => {
      /*EchoInstance.disconnect()

      EchoInstance.connect()*/
      isUserLogin(() => {
        this.$data.projects = projects
      }, () => {
        if (SelectedCountry == 'CL') {
          this.$data.projects = projects
        } else {
          this.$data.projects = projects.filter((project: Project) => project.proyecto_tipo_id == 1)
        }
      })
      this.$data.canShow = true

      let self = this

      this.$data.projects.forEach((project: Project) => {
        if (project.currentAmountTotalPercentage() < 80) {
          /*EchoInstance.channel(`proyecto${project.id}`).listen('ProyectInvestment', function(data: any) {
            API.getProjectAmounts(project.id).then(function(amounts: any) {
              self.$data.projects.find((_project: any) => _project.id == project.id).updateAmounts(amounts.get_monto_proyecto, amounts.get_monto_proyecto_inversion_tmp, amounts.get_monto_reserva, amounts.monto_disponible)
            })
          })*/
        }
      })

      // @ts-ignore
      this.updateTemporals(this.$data.projects.map((project: Project) => project.id))
    })

    EventBus.$on('appendProjects', (projects: Array<Project>) => {
      isUserLogin(() => {
        this.$data.projects.push(...projects)
      }, () => {
        if (SelectedCountry == 'CL') {
          this.$data.projects.push(...projects)
        } else {
          this.$data.projects.push(...projects.filter((project: Project) => project.proyecto_tipo_id == 1))
        }
      })
      // @ts-ignore
      this.updateTemporals(projects.map((project: Project) => project.id))
    })

    EventBus.$on('cantPressButtons', (cantPressButtons: boolean) => {
      this.$data.cantPressButtons = cantPressButtons
    })

    isUserLogin(() => {
      API.getSecretProjects().then( (projects: Array<Project>) => {
        this.$data.secretProjects = projects
        // @ts-ignore
        this.updateTemporals(projects.map((project: Project) => project.id))
      })
    })
  },
  mounted() {
    EventBus.$emit('getProjects')
    if (!this.$store.state.onlyActives) {
      EventBus.$on('appendProjects', () => {
        this.$data.canLoad = true
      })
      EventBus.$on('app-scroll-event', (y: any) => {
        if (this.$route.name != 'investment') return

        // @ts-ignore
        let max = (document.querySelector('.investment').clientHeight - document.querySelector('nav').clientHeight - 400)
        if (document.querySelector('.project-card:last-child')) {
          // @ts-ignore
          max -= document.querySelector('.project-card:last-child').clientHeight
        }

        this.$data.coords = { max, y }

        if (y > max) {
          if (this.$data.canLoad) {
            this.$data.canLoad = false
            // @ts-ignore
            this.viewMoreProjects()
            return
          }
        }
      })
    }
    setTimeout(() => this.$data.canLoad= true, 1500)
  },
  beforeDestroy() {
    EventBus.$off(['projects', 'appendProjects', 'cantPressButtons'])
  },
  methods: {
    viewMoreProjects() {
        EventBus.$emit('viewMoreProjects')
    },
    async updateTemporals(ids: Array<number>) {
      await isUserLogin(async () => {
        let inversionesTmps: any = (await API.getTemporalInvestmentsByUserId())
        ids.forEach((id: any) => {
          if (inversionesTmps.map((tmp: any) => tmp.proyecto_id).includes(id)) {
            let monto = inversionesTmps.find((tmp: any) => tmp.proyecto_id == id).monto
            this.$data.projects.find((project: Project) => project.id == id).subtractToCurrentAmount(monto)
          }
        })
      })
    },
  },
  components: {
    Filters,
    ProjectCard,
  },
})
export default class Investment extends Vue {}
