import { render, staticRenderFns } from "./ProjectCard.vue?vue&type=template&id=e6e39384&scoped=true&"
import script from "./ProjectCard.vue?vue&type=script&lang=ts&"
export * from "./ProjectCard.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectCard.vue?vue&type=style&index=0&id=e6e39384&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6e39384",
  null
  
)

export default component.exports